var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.show)?_c('div',{staticClass:"modal modal__right",class:{ show: _vm.show === true },style:({ display: 'block' }),attrs:{"tabindex":"","aria-labelledby":"modalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal__dialog",attrs:{"role":"document"}},[_c('transition',{attrs:{"name":"slide","appear":""}},[_c('div',{staticClass:"modal__content"},[_c('div',{staticClass:"modal__head"},[_c('div',{staticClass:"modal__head__icon"},[_c('a',{staticClass:"modal__close modal__close--black",on:{"click":_vm.closeModal}},[_c('ion-icon',{attrs:{"name":"arrow-back-outline","data-dismiss":"modal","id":"formCloseButton","aria-label":"Close","disabled":_vm.isSaving},on:{"click":_vm.closeModal}})],1)]),_c('h5',{staticClass:"modal__head__title text--capital"},[_vm._v("Edit Bank")])]),_c('div',{staticClass:"modal__body"},[_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form__item"},[_c('label',{staticClass:"form__label"},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"input form__input form__input--lg",class:{
                      'input-required': _vm.formErr.name,
                    },attrs:{"type":"text","placeholder":"Enter bank name"},domProps:{"value":(_vm.form.name)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)},_vm.validateForm]}})])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form__item"},[_c('label',{staticClass:"form__label"},[_vm._v("Sort Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sortCode),expression:"form.sortCode"}],staticClass:"input form__input form__input--lg",class:{
                      'input-required': _vm.formErr.sortCode,
                    },attrs:{"type":"text","placeholder":"Enter sort code"},domProps:{"value":(_vm.form.sortCode)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "sortCode", $event.target.value)},_vm.validateSortCode]}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form__item"},[_c('label',{staticClass:"form__label"},[_vm._v("NIBBS Bank Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nibssBankCode),expression:"form.nibssBankCode"}],staticClass:"input form__input form__input--lg",class:{
                      'input-required': _vm.formErr.nibssBankCode,
                    },attrs:{"type":"text","placeholder":"Enter NIBBS code"},domProps:{"value":(_vm.form.nibssBankCode)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nibssBankCode", $event.target.value)},_vm.validateNibbsCode]}})])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form__item"},[_c('label',{staticClass:"form__label"},[_vm._v("flutterwave bank code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.flutterWaveBankCode),expression:"form.flutterWaveBankCode"}],staticClass:"input form__input form__input--lg",class:{
                      'input-required': _vm.formErr.flutterWaveBankCode,
                    },attrs:{"type":"text","placeholder":"Enter Flutterwave bank code"},domProps:{"value":(_vm.form.flutterWaveBankCode)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "flutterWaveBankCode", $event.target.value)},_vm.validateFlutterWaveCode]}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form__item"},[_c('label',{staticClass:"form__label"},[_vm._v("Paystack Bank Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.payStackBankCode),expression:"form.payStackBankCode"}],staticClass:"input form__input form__input--lg",class:{
                      'input-required': _vm.formErr.payStackBankCode,
                    },attrs:{"type":"text","placeholder":"Enter paystack bank code"},domProps:{"value":(_vm.form.payStackBankCode)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "payStackBankCode", $event.target.value)},_vm.validatePayStackCode]}})])])]),_c('div',{staticClass:"form__item"},[_c('div',{staticClass:"form__action"},[(!_vm.isSaving)?_c('button',{staticClass:"button form__button form__button--lg w-30 save-changes",attrs:{"type":"submit"}},[_vm._v(" save changes ")]):_vm._e(),(_vm.isSaving)?_c('a',{staticClass:"button form__button form__button--lg w-25 save-changes",attrs:{"role":"button"}},[_c('div',{staticClass:"spinner-border text-light",attrs:{"role":"status"}})]):_vm._e(),(!_vm.isSaving)?_c('a',{staticClass:"modal__close form__action__text form__action__text--danger text--capital ml6",attrs:{"role":"button"},on:{"click":_vm.closeModal}},[_vm._v(" cancel ")]):_vm._e()])])])])])])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }